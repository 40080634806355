import { useParams, useNavigate} from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";

const BlogDetails = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`https://strapi-backend-server-app.onrender.com/api/blogs?filters[slug][$eq]=${slug}&populate=*`)
      .then((response) => {
        console.log("Single Blog API Response:", response.data); // Debugging API response
        const postData = response.data?.data?.[0];

        if (postData) {
          setPost(postData);
        } else {
          console.error("Blog post not found");
        }
      })
      .catch((error) => console.error("Error fetching blog post:", error))
      .finally(() => setLoading(false));
  }, [slug]);

  if (loading) return <p className="loading-text">Loading...</p>;
  if (!post) return <p>Blog post not found</p>;

  // Extract Title, Content, and Image correctly
  const Title = post?.Title || "No Title";
  const Content = post?.Content || [];
  const Image = post?.Image || null;

  // Corrected Image URL extraction
  const imageUrl = Image?.url
    ? `http://localhost:1337${Image.url}`
    : Image?.attributes?.url
    ? `http://localhost:1337${Image.attributes.url}`
    : "path/to/default/image.jpg";

  return (
    <div className="blog-details">
      <img src={imageUrl} alt={Title} className="blog-details__image" />
      <h1>{Title}</h1>
      <div>
        {Content.length > 0
          ? Content.map((block, index) =>
              block?.children?.map((child) => child.text).join(" ")
            )
          : "No content available"}
      </div>
      <div className="back-arrow" onClick={() => navigate(-1)}>
         <span>← Back to Blog</span>
     </div>
    </div>
  );
};

export default BlogDetails;

