import Header from "../../components/Header";
import HeaderImgae from "../../images/header-bg.jpg";

const Developer = () => {
  return (
    <>
      <Header title="Developer Section" image={HeaderImgae}></Header>
      <section className="developer-section">
        <div className="container">
          <div className="api-doc">
            <h2>Agiphi API Documentation</h2>
            <p>
              Welcome to the Agiphi API Documentation. This document provides
              details for clients and developers on how to interact with the
              AgiCrowds platform programmatically.
            </p>

            <h3>Authentication</h3>
            <p>All requests must include an API key in the headers:</p>
            <pre>
              <code>Authorization: Bearer YOUR_API_KEY</code>
            </pre>

            <h4>Obtaining API Key</h4>
            <ol>
              <li>Log in to the Agiphi Developer Portal.</li>
              <li>Navigate to the API Keys section.</li>
              <li>Generate and copy your API key.</li>
            </ol>

            <h3>Endpoints</h3>
            <h4>1. Upload Dataset</h4>
            <p>
              <strong>POST /api/v1/datasets/upload</strong>
            </p>
            <p>
              <strong>Headers:</strong>
            </p>
            <pre>
              <code>
                Authorization: Bearer YOUR_API_KEY Content-Type: application/json
              </code>
            </pre>
            <p>
              <strong>Body:</strong>
            </p>
            <pre>
              <code>{`{
  "name": "Dataset Name",
  "description": "Dataset Description",
  "data": [
    { "field1": "value1", "field2": "value2" },
    { "field1": "value3", "field2": "value4" }
  ]
}`}</code>
            </pre>
            <p>
              <strong>Response:</strong>
            </p>
            <pre>
              <code>{`{
  "status": "success",
  "dataset_id": "12345"
}`}</code>
            </pre>

            <h4>2. Retrieve Dataset</h4>
            <p>
              <strong>GET /api/v1/datasets/{`{dataset_id}`}</strong>
            </p>
            <p>
              <strong>Headers:</strong>
            </p>
            <pre>
              <code>Authorization: Bearer YOUR_API_KEY</code>
            </pre>
            <p>
              <strong>Response:</strong>
            </p>
            <pre>
              <code>{`{
  "dataset_id": "12345",
  "name": "Dataset Name",
  "description": "Dataset Description",
  "data": [
    { "field1": "value1", "field2": "value2" },
    { "field1": "value3", "field2": "value4" }
  ]
}`}</code>
            </pre>

            <h4>3. Delete Dataset</h4>
            <p>
              <strong>DELETE /api/v1/datasets/{`{dataset_id}`}</strong>
            </p>
            <p>
              <strong>Headers:</strong>
            </p>
            <pre>
              <code>Authorization: Bearer YOUR_API_KEY</code>
            </pre>
            <p>
              <strong>Response:</strong>
            </p>
            <pre>
              <code>{`{
  "status": "success",
  "message": "Dataset deleted successfully"
}`}</code>
            </pre>
          </div>

          <div className="resource">
            <h2>Our Resources</h2>
            <p>Find guides, tutorials, and SDKs for integrating AgiCrowds.</p>
            <a href="/resources" className="btn">
              View Resources
            </a>
          </div>

          <div className="agiphi-developer">
            <h2>Agiphi Developer Program</h2>
            <p>Join our developer community and contribute to our ecosystem.</p>
            <a href="/developer-program" className="btn">
              Join Program
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Developer;
