import { motion } from "framer-motion";
import Header from "../../components/Header";
import HeaderImage from "../../images/header-bg.jpg";
import "./careers.css";

const Careers = () => {
  return (
    <>
      <Header title="Join Our Team" image={HeaderImage}>
        Build the Future with Us. Innovate, Collaborate, Succeed.
      </Header>
      <section className="about__story">
        <div className="container about__story-container">
          <div className="about__story-text">
            <motion.h2
              initial={{ x: -100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{
                delay: 0.3,
                x: { type: "spring", stiffness: 60 },
                opacity: { duration: 1.2 },
                ease: "easeIn",
                duration: 1,
              }}
            >
              Current Opportunities
            </motion.h2>
            <motion.p
              initial={{ x: -100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{
                delay: 0.3,
                x: { type: "spring", stiffness: 60 },
                opacity: { duration: 1.2 },
                ease: "easeIn",
                duration: 1,
              }}
              className="write-up"
            >
              We are always looking for talented individuals to join our team. Explore our current openings and find your place at Agiphi.
            </motion.p>
            <iframe 
              src="https://script.google.com/macros/s/AKfycbzLtKfjmh6JLYVnZi8rqwnFbB7oMJPLWCUpnLiLZkeTdOLixQhiWdPgi9lpcwMRLuK0/exec" 
              width="100%" 
              height="800" 
              style={{ border: "none", marginTop: "20px" }}
              title="Agiphi Careers Application"
            ></iframe>
          </div>
        </div>
        <div className="career__development">
          <div className="career__development-text">
            <h2>Career Development</h2>
            <p>We value continuous learning and development. Our team members have access to ongoing training and career advancement opportunities.</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Careers;
