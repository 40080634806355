import Header from "../../components/Header";
import HeaderImgae from "../../images/header-bg.jpg";
import Marquee from "react-fast-marquee";
// import ProductOne from "../../images/StockCake-Data.jpg";
import ProductTwo from "../../images/Tech Support Team.jpg";
import { partnersLogo } from "../../data";
import "./services.css";
import "../../components/mainHeader.css";

const Client = () => {
  return (
    <>
      <Header title="Our Services" image={HeaderImgae}></Header>
      <section className="client-onboarding">
        <div className="container">
          <div className="main__header-container">
            <div className="main__header-left">
              <h2>Start Your Journey with Us</h2>
              <p className="text">
                Our streamlined onboarding process ensures that you can begin benefiting from our services quickly and efficiently.
                We provide all the necessary support to integrate our solutions into your workflow.
              </p>
            </div>
            <div className="main__header-right">
              <div className="main__header-image">
                <img src={ProductTwo} alt="Data analyst" className="analysis" />
              </div>
            </div>
          </div>
          
          <div className="onboarding-steps">
            <div className="card">
              <h3>Initial Consultation</h3>
              <p>We'll discuss your needs and goals to tailor our services specifically to your requirements.</p>
            </div>
            <div className="card">
              <h3>Service Proposal</h3>
              <p>Receive a customized service proposal designed to meet your unique needs.</p>
            </div>
            <div className="card">
              <h3>Onboarding and Training</h3>
              <p>Get set up and trained on our platform to start leveraging our services.</p>
            </div>
          </div>
          
          <div className="key-partners">
            <h2>Integrated Stacks</h2>
            <p>
              We leverage a variety of trusted tools to enhance our Data and ML solutions. Our system seamlessly integrates with the following:
            </p>
            <div className="partner__logo">
              <Marquee autoFill={true}>
                {partnersLogo.map((logo, index) => {
                  return (
                    <div className="partner-logo" key={index}>
                      <img src={logo.src} alt={logo.alt} />
                    </div>
                  );
                })}
              </Marquee>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Client;
