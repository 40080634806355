import {useState} from "react"
import {NavLink} from "react-router-dom"
import Logo from "../../images/Agiphi (3).PNG"
import LogoLight from "../../images/Agiphi (2).PNG"
import { FaBars } from "react-icons/fa6";
import { MdOutlineClose } from "react-icons/md";
import { IoSunnyOutline } from "react-icons/io5";
import { IoMoonSharp } from "react-icons/io5";
import './navbar.css'

const Navbar = ({theme, setTheme}) => {
const toggle_handle = ()=>{
  theme === "dark" ? setTheme("light") : setTheme("dark");
}

const [isNavShowing, setIsNavShowing] = useState(false)
  return (
   <nav>
    <div className=" nav__container">
      <NavLink to="/" className="logo" onClick = {()=> setIsNavShowing(false)}>
        <img src={ theme === "dark" ? Logo : LogoLight} alt="nav-logo" />
      </NavLink>
   
    <ul className={`nav__links ${isNavShowing ? 'show__nav' : 'hide__nav'}`}>
        <li><NavLink to="/" className={({isActive})=> isActive ? 'active-link' : ''} onClick={()=>setIsNavShowing(prev => !prev)}>Home</NavLink></li>
        <li><NavLink to="/agiCrowds" className={({isActive})=> isActive ? 'active-link' : ''} onClick={()=>setIsNavShowing(prev => !prev)}>AgiCrowds</NavLink></li>
        <li><NavLink to="/Services" className={({isActive})=> isActive ? 'active-link' : ''} onClick={()=>setIsNavShowing(prev => !prev)}>Services</NavLink></li>      
        <li><NavLink to="/caseStudy" className={({isActive})=> isActive ? 'active-link' : ''} onClick={()=>setIsNavShowing(prev => !prev)}>Case studies</NavLink></li>
        <li><NavLink to="/Contact" className={({isActive})=> isActive ? 'active-link' : ''} onClick={()=>setIsNavShowing(prev => !prev)}>Contact</NavLink></li>
        <li><NavLink to="/Blog" className={({isActive})=> isActive ? 'active-link' : ''} onClick={()=>setIsNavShowing(prev => !prev)}>Blog</NavLink></li>
        <li><NavLink to="/Careers" className={({isActive})=> isActive ? 'active-link' : ''} onClick={()=>setIsNavShowing(prev => !prev)}>Careers</NavLink></li>
        <li><NavLink to="/Developer" className={({isActive})=> isActive ? 'active-link' : ''} onClick={()=>setIsNavShowing(prev => !prev)}>Developer</NavLink></li>
    </ul>

    <button className="nav__toggle" onClick={()=>setIsNavShowing(prev => !prev)}>
      {
        isNavShowing ? <MdOutlineClose /> : <FaBars />
      }
    </button>
    <div onClick={toggle_handle} className="toggle__icon">
          {theme === "dark" ? <IoSunnyOutline /> : <IoMoonSharp />}
        </div>
   
    </div>
   </nav>
   
  )
}

export default Navbar