import Header from '../../components/Header';
import HeaderImage from '../../images/header-bg.jpg';
import Screenshot1 from '../../images/Agicrowds1.png'; // Importing the first screenshot
import Screenshot2 from '../../images/Agicrowds2.png'; // Importing the second screenshot
import { Link } from 'react-router-dom';
import './agiCrowds.css';

const Platform = () => {
  return (
    <>
      <Header title="Agicrowds Data Platform" image={HeaderImage} />
      <div className="container platform">
        
        {/* Introduction Section */}
        <section className="platform-development">
          <div className="text">
            <h2>Multimodal Data Collection and Annotation Platform</h2>
            <p>
              Agicrowds is a powerful AI-driven platform designed for seamless real-time 
              data collection and annotation across text, audio, and video. 
            </p>
          </div>
          <ul className="key-features">
            <li>
              <strong>Workforce Management:</strong> 
              Track progress dynamically with live status updates and analytics dashboards.
            </li>
            <li>
              <strong>Seamless Integration:</strong> 
              Automate data uploads, annotations, and retrieval using our robust API. 
            </li>
          </ul>

          {/* Sign-Up Button */}
          <div className="button-container">
            <Link to="https://acrowds.com" className="btn" target="_blank" rel="noopener noreferrer">
              Sign Up for Agicrowds
            </Link>
          </div>
        </section>

         {/* Pricing Tool Section */}
        <section className="pricing-section">
          <h2>Agicrowds Pricing Tool</h2>
          <p>
            Get a customized pricing estimate for your data annotation projects.  
            Select features and instantly see pricing adjustments in real-time.
          </p>
          <div className="pricing-iframe-container">
            <iframe 
              src="https://script.google.com/macros/s/AKfycbzeIdeCig5N2mwoJ1ntxO9pJ4CpxfkzNBq7_kXSgYqwG8j1ph8xL7EpROA_c91eqmTw/exec"
              width="100%"
              height="600px"
              style={{ border: 'none' }}
              title="Agicrowds Pricing Tool"
            ></iframe>
          </div>
        </section>

        {/* Screenshots & Demo Section */}
        <section className="visuals-demo">
          <h2>Platform Preview</h2>
          <div className="screenshots">
            <img src={Screenshot1} alt="Screenshot of Agicrowds Annotation Platform" />
            <img src={Screenshot2} alt="Another screenshot of Agicrowds Annotation Platform" />
          </div>
        </section>

        {/* Booking Section */}
        <section className="booking-section">
          <h2>Schedule a Discovery Meeting</h2>
          <p>
            Want to learn more about Agicrowds? Schedule a call with our team to explore 
            its features and see how it fits your needs.
          </p>
          <div className="button-container">
            <a href="https://outlook.office365.com/owa/calendar/DiscoveryMeeting@agiphi.com/bookings/" 
               className="btn" target="_blank" rel="noopener noreferrer">
              Book a Meeting
            </a>
          </div>
        </section>

      </div>
    </>
  );
};

export default Platform;
