import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Header from "../../components/Header";
import HeaderImage from "../../images/header-bg.jpg";
import "../blog/blog.css";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch("https://strapi-backend-server-app.onrender.com/api/blogs?populate=*");
        const data = await response.json();
        console.log("API Response:", data); // Debugging response
        setBlogs(data.data || []);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchBlogs();
  }, []);

  if (loading) {
    return <p className="loading-text">Loading...</p>;
  }

  return (
    <>
      <Header title="Insights and Updates" image={HeaderImage}>
        Constantly updating and providing insights on great innovations and
        development on varieties of topics and our products as well.
      </Header>

      <section className="blog__container">
        <div className="container">
          <div className="blog__text">
            <h2>Recent Posts</h2>
            <p>
              Stay updated with our latest insights on AI training, data
              annotation, and industry trends.
            </p>
          </div>

          <div className="blog onboarding-steps">
            {blogs.map((blog) => {
              const { id, Title, slug, Image } = blog;
              
              // Ensure Image field exists and has the correct structure
              const imageUrl = Image?.url
                ? `http://localhost:1337${Image.url}`
                : "path/to/default/image.jpg"; // Provide a default image URL

              return (
                <div key={id} className="blog__item">
                  <img src={imageUrl} alt={Title || "No Title"} />
                  <h3>{Title || "No title available"}</h3>
                  {/* <p>{Excerpt || "No excerpt available"}</p> */}
                  <Link to={`/blog/${slug}`} className="btn">Read More</Link>
                </div>
              );
            })}
          </div>

          <div className="subscription-section">
            <h2>Subscribe to Our Blog</h2>
            <p>Never miss an update. Subscribe to receive the latest articles and insights.</p>
            <form className="subscription-form">
              <input type="email" placeholder="Email address" required />
              <button type="submit" className="btn">Subscribe</button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
