import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import "../../components/mainHeader.css";
import "../casestudy/caseStudy.css";
import HeaderImage from "../../images/header-bg.jpg";

const CaseStudies = () => {
  const [caseStudies, setCaseStudies] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCaseStudies = async () => {
      try {
        const response = await fetch("https://strapi-backend-server-app.onrender.com/api/casestudysecs?populate=*");
        const data = await response.json();
        console.log("Fetched Data:", data);
        setCaseStudies(data.data); // Set the case studies
        setLoading(false);
      } catch (error) {
        console.error("Error fetching case studies:", error);
        setLoading(false);
      }
    };
    fetchCaseStudies();
  }, []);

  if (loading) {
    return <p className="loading-text">Loading...</p>;
  }

  if (!caseStudies.length) {
    return <p>No case studies available.</p>;
  }

  return (
    <>
      <Header title="Case Studies" image={HeaderImage}>
        Transforming AI initiatives worldwide with superior high-quality data.
      </Header>
      <section className="about__story">
        <div className="container about__story-container">
          <div className="grid__container">
          {caseStudies.map((caseStudy) => {
    console.log("Case Study Data:", caseStudy); // Debugging: Log entire case study object
    const { id, Title, slug, image, description } = caseStudy;
    console.log("Slug Value:", slug); // Debugging: Check if Slug is undefined

    const imageUrl = image?.formats?.thumbnail?.url
  ? `http://localhost:1337${image.formats.thumbnail.url}`
  : "path/to/default-image.jpg"; 

    const caseDescription = description?.[0]?.children?.[0]?.text || "No description available.";

              return (
                <div key={id} className="case-study-card">
                  <div className="image-story">
                    <img src={imageUrl} alt={Title || "Untitled Case Study"} />
                  </div>
                  <h2>{Title || "Untitled Case Study"}</h2>
                  <p>{caseDescription}</p>
                  <Link to={`/case-study/${slug}`} className="btn case-btn">
                    Read More
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default CaseStudies;
