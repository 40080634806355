import React, { useState } from 'react';
import MainHeader from '../../components/MainHeader';
import CompanyOverview from '../../components/CompanyOverview';
import '../home/home.css';
import OurServices from '../../components/OurServices';

const Home = () => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <>
      <MainHeader />
      <CompanyOverview />
      <OurServices />
      
      {/* Google Calendar Popup Button */}
      <section className="calendar-section">
        <button className="calendar-button" onClick={togglePopup}>
          Book an Appointment
        </button>
        {showPopup && (
          <div className="calendar-popup">
            <div className="popup-content">
              <span className="close-button" onClick={togglePopup}>&times;</span>
              <iframe
                src="https://outlook.office365.com/owa/calendar/DiscoveryMeeting@agiphi.com/bookings/"
                style={{ border: 'none', width: '100%', height: '500px' }}
                frameBorder="0"
                title="Calendar Scheduler"
              ></iframe>
            </div>
          </div>
        )}
      </section>
      
      <style>
        {`
          .calendar-button {
            padding: 10px 20px;
            background-color: #616161;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 16px;
          }
          .calendar-popup {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .popup-content {
            background: white;
            padding: 20px;
            border-radius: 10px;
            width: 600px;
            position: relative;
          }
          .close-button {
            position: absolute;
            top: 10px;
            right: 15px;
            font-size: 24px;
            cursor: pointer;
          }
        `}
      </style>
    </>
  );
};

export default Home;
